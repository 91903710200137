html {
	line-height: 1.15;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
body {
	margin: 0;
}
article,
aside,
footer,
header,
nav,
section {
	display: block;
}
h1 {
	font-size: 2em;
	margin: 0.67em 0;
}
figcaption,
figure,
main {
	display: block;
}
figure {
	margin: 1em 40px;
}
hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}
pre {
	font-family: monospace, monospace;
	font-size: 1em;
}
a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}
abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}
b,
strong {
	font-weight: inherit;
}
b,
strong {
	font-weight: bolder;
}
code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}
dfn {
	font-style: italic;
}
mark {
	background-color: #ff0;
	color: #000;
}
small {
	font-size: 80%;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sub {
	bottom: -0.25em;
}
sup {
	top: -0.5em;
}
audio,
video {
	display: inline-block;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
img {
	border-style: none;
}
svg:not(:root) {
	overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
	font-family: sans-serif;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}
button,
input {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}
fieldset {
	padding: 0.35em 0.75em 0.625em;
}
legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}
progress {
	display: inline-block;
	vertical-align: baseline;
}
textarea {
	overflow: auto;
}
[type="checkbox"],
[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}
details,
menu {
	display: block;
}
summary {
	display: list-item;
}
canvas {
	display: inline-block;
}
template {
	display: none;
}
[hidden] {
	display: none;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: "";
	clear: both;
	height: 0;
}

* {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

body {
	font-size: 125%;
}

.App {
	background: rgb(76, 106, 104);
	background: -moz-linear-gradient(
		215deg,
		rgba(76, 106, 104, 1) 0%,
		rgba(9, 48, 43, 1) 100%
	);
	background: -webkit-linear-gradient(
		215deg,
		rgba(76, 106, 104, 1) 0%,
		rgba(9, 48, 43, 1) 100%
	);
	background: linear-gradient(
		215deg,
		rgba(76, 106, 104, 1) 0%,
		rgba(9, 48, 43, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4c6a68",endColorstr="#09302b",GradientType=1);
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container {
	text-align: center;
}

.container h1 {
	background: #f4efae;
	background: -webkit-radial-gradient(
		circle farthest-corner at center center,
		#f4efae 0%,
		#957547 100%
	);
	background: -moz-radial-gradient(
		circle farthest-corner at center center,
		#f4efae 0%,
		#957547 100%
	);
	background: radial-gradient(
		circle farthest-corner at center center,
		#f4efae 0%,
		#957547 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
	text-transform: uppercase;
	font-family: "Lato", sans-serif;
	font-size: 3.5rem;
	margin: 0;
	font-weight: 700;
}

.container h2 {
	background: #f4efae;
	background: -webkit-radial-gradient(
		circle farthest-corner at center center,
		#f4efae 0%,
		#957547 100%
	);
	background: -moz-radial-gradient(
		circle farthest-corner at center center,
		#f4efae 0%,
		#957547 100%
	);
	background: radial-gradient(
		circle farthest-corner at center center,
		#f4efae 0%,
		#957547 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
	text-transform: uppercase;
	font-family: "Dosis", sans-serif;
	font-size: 3.38rem;
	margin: 0;
}

.contact {
	margin-top: 40px;
}

.contact a {
	color: white;
	text-decoration: none;
	margin: 20px;
	border: 2px solid #f4efae;
	padding: 10px 30px;
	border-radius: 20px;
	transition: all 0.25s;
	font-family: "Lato", sans-serif;
	font-weight: 400;
	text-transform: uppercase;
}

.contact a:hover,
.contact a:focus,
.contact a:active {
	background-color: #f4efae;
	color: #365754;
	transition: all 0.25s;
}

@media (max-width: 800px) {
	.container h1 {
		font-size: 2.5rem;
	}
	.container h2 {
		font-size: 2.4rem;
	}
}

@media (max-width: 450px) {
	.contact a {
		margin: 5px;
		padding: 20px 20px;
	}
	.container h1 {
		font-size: 2rem;
	}
	.container h2 {
		font-size: 1.9rem;
	}
}
